<template>
  <div class="content-item">
    <div ref="contentFeaturedImg" class="content-img"></div>
    <div class="content-text">
      <h5>{{ content.title }}</h5>
      <p class="sub-text-s3 text-gray-second">{{ content.subTitle }}</p>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
  name: "ContentItem",
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const contentFeaturedImg = ref();

    onMounted(() => {
      contentFeaturedImg.value.style.backgroundImage = `url(${props.content.iconImage})`;
    });

    return { contentFeaturedImg };
  },
};
</script>

<style src="./style.css" scoped></style>

<template>
  <div class="meeting-list__pc">
    <button
      v-if="themeLive.themeClubs.length > 6"
      class="swiper-button-prev"
      :disabled="!state.activeThemeLiveSwiperPrevBtn"
      @click="actions.prevSwiperThemeLive()"
    >
      <i class="fa-solid fa-chevron-left"></i>
    </button>
    <button
      v-if="themeLive.themeClubs.length > 6"
      class="swiper-button-next"
      :disabled="!state.activeThemeLiveSwiperNextBtn"
      @click="actions.nextSwiperThemeLive()"
    >
      <i class="fa-solid fa-chevron-right"></i>
    </button>

    <swiper
      :slides-per-view="6"
      :space-between="24"
      :pagination="{
        type: 'fraction',
      }"
      :navigation="
        themeLive.themeClubs.length > 6
          ? {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }
          : false
      "
      :breakpoints="{
        1024: {
          slidesPerView: 6,
        },
      }"
      @swiper="onThemeLiveSwiper"
      @slideChange="actions.onThemeLiveSlideChange()"
    >
      <swiper-slide
        v-for="themeClub in themeLive.themeClubs"
        :key="`meeting-${themeClub.resourceId}`"
      >
        <theme-live-item
          :theme-club="themeClub"
          @click="actions.prevThemeClub(themeClub)"
        ></theme-live-item>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import ThemeLiveItem from "../ThemeLiveItem/ThemeLiveItem";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { reactive } from "vue";

export default {
  name: "ThemeLiveGroup",
  components: { ThemeLiveItem, Swiper, SwiperSlide },
  props: {
    themeLive: {
      type: Object,
      required: true,
    },
  },
  emits: ["prevThemeClub"],
  setup(props, { emit }) {
    const state = reactive({
      themeLiveSwiper: null,
      activeThemeLiveSwiperPrevBtn: false,
      activeThemeLiveSwiperNextBtn: true,
    });

    const onThemeLiveSwiper = (swiper) => {
      state.themeLiveSwiper = swiper;
    };

    const actions = {
      onThemeLiveSlideChange: () => {
        if (state.themeLiveSwiper.activeIndex === 0) {
          state.activeThemeLiveSwiperPrevBtn = false;
          state.activeThemeLiveSwiperNextBtn = true;
          return;
        }
        if (state.themeLiveSwiper.isEnd) {
          state.activeThemeLiveSwiperNextBtn = false;
          state.activeThemeLiveSwiperPrevBtn = true;
          return;
        }
        state.activeThemeLiveSwiperPrevBtn = true;
        state.activeThemeLiveSwiperNextBtn = true;
      },
      prevSwiperThemeLive: () => {
        state.themeLiveSwiper.slidePrev();
      },
      nextSwiperThemeLive: () => {
        state.themeLiveSwiper.slideNext();
      },
      prevThemeClub: (theme) => {
        emit("prevThemeClub", theme);
      },
    };

    return { state, actions, onThemeLiveSwiper };
  },
};
</script>

<style src="./style.css" scoped></style>

<template>
  <icon-base
    icon-name="card-ribbon-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      d="M0 2C0 0.895432 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V46.7639C40 48.2507 38.4354 49.2177 37.1056 48.5528L20.8944 40.4472C20.3314 40.1657 19.6686 40.1657 19.1056 40.4472L2.89443 48.5528C1.56463 49.2177 0 48.2507 0 46.7639V2Z"
      :fill="fillColor"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
export default {
  name: "CardRibbonIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 40 49",
    },
    width: {
      type: [Number, String],
      default: 40,
    },
    height: {
      type: [Number, String],
      default: 49,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#B28BFF",
    },
  },
};
</script>

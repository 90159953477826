<template>
  <div class="theme-item" :title="themeClub.title">
    <div class="theme-ribbon">
      <recommend-theme-ribbon text="NEW"></recommend-theme-ribbon>
    </div>
    <div class="theme-card">
      <img
        class="featured-img"
        :src="state.featuredImage"
        :alt="`${themeClub.title} 이미지`"
      />
      <img
        v-if="themeClub.logoImage"
        class="logo-image"
        :src="themeClub.logoImage"
        :alt="`${themeClub.title} 로고 이미지`"
      />
    </div>

    <div class="theme-text">
      <h6 v-if="themeClub.themeClubGroup" class="title text-purple-20">
        {{ themeClub.themeClubGroup.title }}
      </h6>
      <p
        v-if="themeClub.title"
        class="description sub-title-s1 text-gray-second"
      >
        {{ themeClub.title }}
      </p>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, reactive } from "vue";
import RecommendThemeRibbon from "../RecommandThemeRibbon/RecommendThemeRibbon";

export default {
  name: "RecommendThemeItem",
  components: { RecommendThemeRibbon },
  props: {
    themeClub: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();

    const state = reactive({
      featuredImage: computed(() => {
        return props.themeClub.featuredImage
          ? props.themeClub.featuredImage
          : proxy.$const.blankImage;
      }),
    });

    return { state };
  },
};
</script>

<style src="./style.css" scoped></style>

<template>
  <base-modal-side :auto-hide="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3 class="text-default">테마 상세 페이지 미리보기</h3>
        <p class="text-gray-second sub-text-s3">
          아래 정보는 빅크에서 제공하는 템플릿으로 얼마든지 수정할 수 있어요.
        </p>
      </div>
    </template>

    <template #modalBody>
      <div class="prev-wrapper dark-mode">
        <div class="bg-gray-studio prev-section">
          <div ref="themeFeatured" class="theme-featured"></div>
          <div class="theme-wrapper text-gray-default">
            <h4 class="text-purple-20">
              {{ themeClub.tags[0] ? themeClub.tags[0].name : "" }}
            </h4>
            <h2 class="text-truncate--row-2">
              {{ state.title }}
            </h2>

            <div class="ticket-select">
              <span class="sub-text-s2">
                {{ state.ticketName }}
              </span>
              <i class="fa-solid fa-angle-down"></i>
            </div>

            <hr class="hr-second" />

            <div class="ticket-info">
              <p class="sub-title-s1 tit">
                {{ state.ticketName }}
              </p>

              <p class="sub-text-s2 text-gray-second description">
                {{ state.ticketDescription }}
              </p>
              <div class="price-area">
                <h2>{{ state.ticketPrice }}</h2>
              </div>
            </div>

            <hr class="hr-second" />

            <div>
              <div ref="themeNavElement" class="tab-buttons">
                <ul>
                  <li class="sub-text-s2">Story</li>
                  <li class="sub-text-s2 text-gray-second">Ticket</li>
                  <li class="sub-text-s2 text-gray-second">Review</li>
                  <li class="sub-text-s2 text-gray-second">Members</li>
                  <li class="sub-text-s2 text-gray-second">FAQ</li>
                </ul>
              </div>

              <div ref="themeNavFillElement"></div>
            </div>
            <div class="description-area">
              <h2>Story</h2>
              <hr class="hr-third" />
              <p class="sub-text-s2" v-html="state.holderDescription"></p>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #modalFooter>
      <button-basic
        text="이 테마 사용하기"
        @action="actions.createThemeClub()"
      ></button-basic>
    </template>
  </base-modal-side>
</template>

<script>
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import { computed, getCurrentInstance, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import helper from "@/helper";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "ThemePrevModal",
  components: { ButtonBasic, BaseModalSide },
  props: {
    themeClub: {
      type: Object,
      required: true,
    },
  },
  emits: ["hideModal", "createThemeClub"],
  setup(props, { emit }) {
    const themeFeatured = ref();
    const themeNavFillElement = ref();
    const themeNavElement = ref();
    const { proxy } = getCurrentInstance();

    const store = useStore();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      title: computed(() => {
        return props.themeClub.holderTitle
          ? `${props.themeClub.holderTitle.replaceAll(
              "{{name}}",
              helper.displayUserName(state.user)
            )}`
          : "";
      }),
      ticketName: computed(() => {
        return `${helper.displayUserName(state.user)}의 ${
          props.themeClub.title
        } 입장권`;
      }),
      ticketDescription: computed(() => {
        return `${helper.displayUserName(state.user)}의 ${
          props.themeClub.title
        } LIVE 참여`;
      }),
      ticketPrice: computed(() => {
        return `${helper.priceFormat(props.themeClub["holderPriceKRW"])}원`;
      }),
      holderDescription: computed(() => {
        return props.themeClub.holderDescription
          ? helper.nl2br(
              props.themeClub.holderDescription.replaceAll(
                "{{name}}",
                helper.displayUserName(state.user)
              )
            )
          : "";
      }),
    });

    onMounted(() => {
      console.log(props.themeClub);

      let linearGradient = "linear-gradient(to bottom, transparent, #0D0D10)";
      themeFeatured.value.style.backgroundImage = `${linearGradient}, url(${
        props.themeClub.holderFeaturedImage
          ? props.themeClub.holderFeaturedImage
          : proxy.$const.blankImage
      })`;

      themeNavFillElement.value.style.height = `${themeNavElement.value.clientHeight}px`;
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      createThemeClub: () => {
        emit("createThemeClub", props.themeClub);
      },
    };

    return {
      state,
      actions,
      themeFeatured,
      themeNavElement,
      themeNavFillElement,
    };
  },
};
</script>

<style src="./style.css" scoped></style>

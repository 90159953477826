<template>
  <div class="recommend-theme-ribbon">
    <card-ribbon-icon></card-ribbon-icon>
    <h5 v-if="text" class="text text-gray-reverse">{{ text }}</h5>
  </div>
</template>
<script>
import CardRibbonIcon from "../../../../components/console/icons/CardRibbonIcon";
export default {
  name: "RecommendThemeRibbon",
  components: { CardRibbonIcon },
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style src="./style.css" scoped></style>

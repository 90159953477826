<template>
  <div class="theme-live-item" :title="themeClub.title">
    <div class="theme-card">
      <img
        class="featured-img"
        :src="state.featuredImage"
        :alt="`${themeClub.title} 이미지`"
      />
      <img
        v-if="themeClub.logoImage"
        class="logo-img"
        :src="themeClub.logoImage"
        :alt="`${themeClub.title} 로고 이미지`"
      />
    </div>

    <div class="theme-text">
      <span v-if="themeClub.title" class="title sub-title-s2 text-gray-second">
        {{ themeClub.title }}
      </span>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, reactive } from "vue";

export default {
  name: "ThemeLiveItem",
  components: {},
  props: {
    themeClub: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();

    const state = reactive({
      featuredImage: computed(() => {
        return props.themeClub.featuredImage
          ? props.themeClub.featuredImage
          : proxy.$const.blankImage;
      }),
    });

    return { state };
  },
};
</script>

<style src="./style.css" scoped></style>
